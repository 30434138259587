import Alpine from 'alpinejs';
import Pusher from "pusher-js";
import Echo from "laravel-echo";

window.Pusher = Pusher

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true
});

window.Echo.private(`App.Context.User.Models.User.1`)
    .listen('BroadcastNotificationCreated', (e) => {
        console.log(e);
    });

Alpine.store('calendar', {
    events:[],
    eventColors:{},
    weeks:[],
    currentMonth: 0,
    currentYear: 0,
    monthLength:0,
    startWeekDay:0,
    lastMonthLength:0,
    firstCalendarDay:0,
    lastCalendarDay:0,
    weekHeadings:{},
    apiErrors:[],
    selectedUsers:[],
})

import './components/index';
