export default function (state = {
  brands: [],
}) {
  return {
    brands: state.brand,

    init() {
      this.$wire.emit('DynamicContainer:Set', {
        name: 'livewire:licences.accreditation-group-list',
        title: 'Brands',
        data: [],
      });
    },

    selectBrandClick (selectedBrand) {
      console.log(selectedBrand);
      this.$wire.emit('DynamicContainer:Set', {
        name: 'livewire:licences.course-by-delivery-list',
        title: 'Courses',
        data: selectedBrand,
      });
    },
  }
}
